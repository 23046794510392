// 组件
import test1 from './test1.vue'

export default {
  components: {
    test1
  },
  data () {
    return {
      activeName: 'second',
      name: 'test1'
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
    handleClick (tab, event) {
      // console.log(tab, event)
    }
  }
}
