import request from '@/utils/request'
/**
 * @name 门店
 */

export const getOffStoresSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/stores/setup'
  })
}

export const getOffStoresList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/stores',
    params
  })
}

export const deleteOffStoreDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/stores/${id}`
  })
}

export const getOffStoredtails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/stores/${id}`
  })
}

export const getOffStoreEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/stores/${id}/preview`
  })
}

export const postOffStoreFormData = (data) => {
  return request({
    method: 'POST',
    url: '/cms/stores',
    data
  })
}

export const putOffStoreFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `/cms/stores/${id}`,
    data
  })
}

export const getPoiableTypeList = () => {
  return request({
    method: 'GET',
    url: '/cms/stores/poiable_types'
  })
}
